import React, { Component } from 'react';
import './App.css';
import { Link, DirectLink, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import SubscribePage from "./SubscribePage.js"


class Footer extends Component {


  render() {

    return (
      <Element name="contact">
      <div className="footer">

           <section className="text_container">
              <div className="first_col">
                  <div className="first_col_container">
                    <div className="footer_title">
                    <p>LEV SITE-SPECIFIC COOKING</p>
                    </div>
                    <div className="footer_flex">
                      <p>email</p> <a href="mailto:info@levvv.co" target="_top">info@levvv.co</a>
                    </div>
                    <div className="footer_socials">
                      <a href="https://www.instagram.com/lev.nyc/" target="_blank">Instagram →</a>
                    </div>
                  </div>
              </div>
              <div className="second_col">
                <div className="second_col_container">

                  <div className="footer_title">
                    <p>MAILING LIST</p>
                  </div>
                    <SubscribePage />
                </div>
              </div>
            </section>
            <div className="back_to_top" id="back-to-top">
                <div>
                <img 
                  src="http://res.cloudinary.com/dhs8w3df1/image/upload/v1511372160/arrow_tsorqc.svg" />
                </div>
                <div>
                <a>Back <br />
                to top </a>
              </div>
            </div>
        </div>
        </Element>
    );
  }
}

export default Footer;
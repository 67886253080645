import React, { Component } from 'react';
import './App.css';
import { Link, DirectLink, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'


class Section_5 extends Component {
  render() {
    return (
      <Element name="story">
      <div className="section_5">
      <header>
      <marquee direction="right">
        STORY <span>STORY</span> STORY <span>STORY</span> STORY <span>STORY</span> STORY
      </marquee>
      </header>

      <section className="text_container">
        <div className="first_col">
          <p>Lev chefs Loren and Daniel grew up in Galilee and Jerusalem respectively. From an early age they were inspired by the melting pot of culinary backgrounds surrounding them. With the rich mix of culinary traditions Jewish immigrants brought from around the world together with the Palestinian traditions, food was always imbued with a deeper sense of memory and emotion.</p>
        </div>
        <div className="second_col">
        <img src="https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1688051261/LEV_PLACEHOLDERS/jp8ohyx6ra3vuzwv3geh.jpg" />
        </div>
      </section>


      <section className="text_container second">
        <div className="first_col">
        <div className='slider'>
          <div className='slide1'>
            <img src="https://res.cloudinary.com/dzzgflvyp/image/upload/v1689194556/LEV/slider_6/2_31.jpg"></img>
          </div>
          <div className='slide2'>
              <img src="https://res.cloudinary.com/dzzgflvyp/image/upload/v1688492616/LEV/slider_6/1200_28.jpg"></img>
          </div>
          <div className='slide3'>
              <img src="https://res.cloudinary.com/dzzgflvyp/image/upload/v1689194797/LEV/slider_6/3_31.jpg"></img>
          </div>
        </div>
        </div>
        <div className="second_col">

        <h1>Simplicity is the backbone of their culinary approach.</h1>

        <div className="section5_mobile">
          <img src="https://res.cloudinary.com/dzzgflvyp/image/upload/v1689091807/LEV/slider_6/2_1200_27.jpg"></img>
        </div>

        <p>In the early 2000s each set out on their own culinary journeys, working in such places as Italy, Spain, Australia, England, France and the US.
Loren worked in Turin at a local trattoria while Daniel apprenticed in a restaurant on the island of Ischia. This formative time taught them cooking fundamentals they still use to this day. The signature aspect being: total respect for the ingredient. Simplicity and attention  remains as the backbone of their culinary approach.</p>


        <p>Now both based in New York, together Loren and Daniel have combined their roots, values, intentions and life’s work into LEV.</p>

        </div>
      </section>

      </div>
      </Element>
    );
  }
}

export default Section_5;

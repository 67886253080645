import React, { Component } from 'react';
import './App.css';
import { Link, DirectLink, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'


class Section_3 extends Component {
  render() {
    return (
      <Element name="food">
      <div className="section_3" id="food">
        <header>
        <marquee direction="right">
          FOOD  <span>FOOD </span> FOOD  <span>FOOD </span> FOOD  <span>FOOD </span> FOOD
        </marquee>
        </header>

        <section className="text_container">
          <div className="first_col">
            <p>We believe people should eat food that is cooked with simple methods, retaining both essence and flavor. Our produce is always selected based on what’s in season.</p>
          </div>
          <div className="second_col">
          <div className='slider'>
              <div className='slide1'>
                <img src="https://res.cloudinary.com/dzzgflvyp/image/upload/v1688492527/LEV/slider_3/11_1800.jpg"></img>
              </div>
              <div className='slide2'>
                  <img src="https://res.cloudinary.com/dzzgflvyp/image/upload/v1688492527/LEV/slider_3/14_1800.jpg"></img>
              </div>
              <div className='slide3'>
                  <img src="https://res.cloudinary.com/dzzgflvyp/image/upload/v1688492527/LEV/slider_3/12_1800.jpg"></img>
              </div>
            </div>
          </div>
        </section>

        <section className="third_grid_container">
          <div className="mob_only">
            <div className="first_col">
            <img src="https://res.cloudinary.com/dzzgflvyp/image/upload/v1688492554/LEV/slider_4/1800_15.jpg" />
            </div>
            <div className="second_col">
            <img src="https://res.cloudinary.com/dzzgflvyp/image/upload/v1688492553/LEV/slider_4/1800_16.jpg" />
            </div>
          </div>

          <div className="first_col">
            <div className='slider'>
              <div className='slide1'>
                <img src="https://res.cloudinary.com/dzzgflvyp/image/upload/v1688492554/LEV/slider_4/1800_15.jpg"></img>
              </div>
              <div className='slide2'>
                  <img src="https://res.cloudinary.com/dzzgflvyp/image/upload/v1688492553/LEV/slider_4/1800_16.jpg"></img>
              </div>
              <div className='slide3'>
                  <img src="https://res.cloudinary.com/dzzgflvyp/image/upload/v1688492553/LEV/slider_4/1800_18.jpg"></img>
              </div>
              <div className='slide4'>
                  <img src="https://res.cloudinary.com/dzzgflvyp/image/upload/v1688492554/LEV/slider_4/1800_20.jpg"></img>
              </div>
            </div>
          </div>
          <div className="second_col">
            <div className='slider'>
              <div className='slide1'>
                <img src="https://res.cloudinary.com/dzzgflvyp/image/upload/v1688492583/LEV/slider_5/1200_21.jpg"></img>
              </div>
              <div className='slide2'>
                  <img src="https://res.cloudinary.com/dzzgflvyp/image/upload/v1688492584/LEV/slider_5/1200_22.jpg"></img>
              </div>
              <div className='slide3'>
                  <img src="https://res.cloudinary.com/dzzgflvyp/image/upload/v1688492584/LEV/slider_5/1200_23.jpg"></img>
              </div>
              <div className='slide4'>
                  <img src="https://res.cloudinary.com/dzzgflvyp/image/upload/v1688492583/LEV/slider_5/1200_25.jpg"></img>
              </div>
            </div>
          </div>
          <div className="third_col">
            <p>We build menus specific to client wishes, taking into consideration any dietary restrictions and personal preferences.</p>
          </div>

        </section>


        <footer>
        <img src="https://res.cloudinary.com/dhs8w3df1/image/upload/c_scale,w_1800/v1511290679/07-israel-photo_fs58we.jpg" />
        </footer>

      </div>
      </Element>
    );
  }
}

export default Section_3;
import React, { Component } from 'react';
import './App.css';
import { Link, DirectLink, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';


class Section_4 extends Component {
  constructor(props) {
    super(props);
    this.click = this.click.bind(this)
    this.state = {
        title: '',
        description : '',
        showPlateText: true,
        brightness: true,
        verticalTitle: ''

    };
  }

  click(){
   this.changeShowPlateText();
   this.changeBrightness();
 }


  changeBrightness(){
     this.setState({brightness: !this.state.brightness})
 }

 changeShowPlateText(){
   this.setState({showPlateText: !this.state.showPlateText})
 }




  render() {

    const DataText = {
      mezze: {
        title: "Mezze offers an endless array of flavors, colors and aromas.",
        desc: "Mezze can travel around the space or can be served at one long table, accompanied with freshly baked breads, a family-style entree and a dessert.",
        mob: "+Show Mezze Menu",
        verticalTitle: "Mezze"
      },
      classic: {
        title: "Classic is a four to seven course meal: hors d’oeuvres, appetizers, entree and dessert.",
        desc: "Classic can be served family-style or plated, perfect for sit-down dinners.",
        mob: "+Show Classic Menu",
        verticalTitle: "Classic"


      },
      fire: {
        title: "Fire becomes the heart of these events. Allowing guests to taste the bold flavors of smoke and charcoal, as we slow-roast whole cuts, grill seafood and vegetables and bake legumes in clay pots.",
        desc: "Next to the fire, you can sit at a long table, where we serve food straight from the pit, fresh salads and appetizers.",
        mob: "+Show Fire Menu",
        verticalTitle: "Fire"


      }
    };

    let brightness = this.state.brightness ? "79%" : "100%";
    let showPlateText = this.state.showPlateText ? "visible" : "hidden";


    var divStyleImg = {
      filter: `brightness( ${brightness} )`
    };

    var StyleText = {
      visibility: showPlateText
    };

    return (
      <Element name="menus">
      <div className="section_4">
      <header>
      <marquee>
        MENU <span>MENU</span> MENU <span>MENU</span> MENU <span>MENU</span> MENU
      </marquee>
      </header>


      <section className="third_grid_container">
        <div className="first_col">
        <a onMouseOver={() => this.setState({ title: DataText.mezze.title, description: DataText.mezze.desc, verticalTitle: DataText.mezze.verticalTitle })} onMouseOut={() => this.setState({ title: "", description: "", verticalTitle: ""  })}>
        <img src="http://res.cloudinary.com/dhs8w3df1/image/upload/v1511290677/08-mezze-photo_ooytop.png" />
        </a>
        </div>
        <div className="second_col">
        <a onMouseOver={() => this.setState({ title: DataText.classic.title, description: DataText.classic.desc, verticalTitle: DataText.classic.verticalTitle })} onMouseOut={() => this.setState({ title: "", description: "", verticalTitle: ""  })}>
        <img src="http://res.cloudinary.com/dhs8w3df1/image/upload/v1511290679/08-classic-photo_iww94a.png" />
        </a>
        </div>
        <div className="third_col">
        <a onMouseOver={() => this.setState({ title: DataText.fire.title, description: DataText.fire.desc, verticalTitle: DataText.fire.verticalTitle })} onMouseOut={() => this.setState({ title: "", description: "", verticalTitle: "" })}>
        <img src="http://res.cloudinary.com/dhs8w3df1/image/upload/v1511290677/08-fire-photo_ybn0w4.png" />
        </a>
        </div>
      </section>

      <footer>

      <div className="menu_vertical">
      <p>{this.state.verticalTitle}</p>
      </div>

      <div>
      <p>{this.state.title}</p>
      <p>{this.state.description}</p>
      </div>

      </footer>

      <section className="third_grid_container_mobile">
        <div id="accordion">
        <div id="accordion_first" className="accordion-toggle accordion_first">
          <div>
          <p id="accordion_first_text">{DataText.mezze.mob}</p>
          </div>
        </div>
          <div id="accordion_content_first" className="accordion-content accordion_content_first">
            <p>{DataText.mezze.title}</p>
            <p>{DataText.mezze.desc}</p>
          </div>


          <div id="accordion_second" className="accordion-toggle accordion_second">
            <div>
            <p id="accordion_second_text">{DataText.classic.mob}</p>
            </div>
          </div>
          <div id="accordion_content_second" className="accordion-content">
            <p>{DataText.classic.title}</p>
            <p>{DataText.classic.desc}</p>
          </div>


          <div id="accordion_third" className="accordion-toggle accordion_third">
            <div>
            <p id="accordion_third_text">{DataText.fire.mob}</p>
            </div>
          </div>
          <div id="accordion_content_third" className="accordion-content">
            <p>{DataText.fire.title}</p>
            <p>{DataText.fire.desc}</p>
          </div>
          </div>
      </section>


      </div>
      </Element>
    );
  }
}

export default Section_4;
import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import { ParallaxProvider } from 'react-scroll-parallax';

import Section_1 from './Section_1';
import Section_2 from './Section_2';
import Section_3 from './Section_3';
import Section_4 from './Section_4';
import Section_5 from './Section_5';
import Footer from './Footer';
import SubscribePage from './SubscribePage';


class App extends Component {
  render() {
    return (
      <div className="App">
          <Section_1 />
          <Section_2 />
          <Section_3 />
          <Section_5 />
          <Footer />
      </div>
    );
  }
}

export default App;
import React, { Component } from 'react';
import './App.css';
import Slider from 'react-slick';
import Marquee from "react-smooth-marquee"

class Section_1 extends Component {

    render() {

    var settings = {
     dots: false,
     infinite: true,
     speed: 2000,
     slidesToShow: 1,
     slidesToScroll: 1,
     autoplay: true,
     autoplaySpeed: 2000,
     useCSS: false,
     swipe: false
    };


    return (
      <section className="section_1">
          <header>
            <img src="http://res.cloudinary.com/dhs8w3df1/image/upload/v1511372160/lev-logo_detalq.svg" />
          </header>
          <section className="slider_container">
          <Slider {...settings}>
              <div><img src='https://res.cloudinary.com/dzzgflvyp/image/upload/v1689091973/LEV/slider_1/1_TEST03.jpg'/></div>
              <div><img src='https://res.cloudinary.com/dzzgflvyp/image/upload/v1688664092/LEV/slider_1/TEST02.jpg'/></div>
              <div><img src='https://res.cloudinary.com/dzzgflvyp/image/upload/v1689004375/LEV/slider_1/TEST05.jpg'/></div>
            </Slider>
          </section>
          <section className="slider_container mobile">
          <Slider {...settings}>
              <div><img src='https://res.cloudinary.com/dzzgflvyp/image/upload/v1689092366/LEV/slider_1/2_IPHONE01.jpg'/></div>
              <div><img src='https://res.cloudinary.com/dzzgflvyp/image/upload/v1689092365/LEV/slider_1/3_IPHONE01.jpg'/></div>
              <div><img src='https://res.cloudinary.com/dzzgflvyp/image/upload/v1689004384/LEV/slider_1/IPHONE02.jpg'/></div>
              <div><img src='https://res.cloudinary.com/dzzgflvyp/image/upload/v1689004384/LEV/slider_1/IPHONE03.jpg'/></div>
            </Slider>
          </section>
          <footer>
            <marquee>
              SITE-SPECIFIC COOKING <span>SITE-SPECIFIC COOKING</span> SITE-SPECIFIC COOKING <span>SITE-SPECIFIC COOKING</span> SITE-SPECIFIC COOKING <span>SITE-SPECIFIC COOKING</span> SITE-SPECIFIC COOKING
            </marquee>
          </footer>

      </section>
    );
  }
}

export default Section_1;
